import { Link } from 'react-router-dom';
import styled from 'styled-components';

type ICollapsedElements = {
  collapsed?: boolean;
  active?: boolean;
};

type ISidebarItem = {
  collapsed?: boolean;
  active?: boolean;
};

type ISidebarIcon = {
  collapsed?: boolean;
  active?: boolean;
};

type ISidebarSection = {
  hasFirm: boolean;
  collapsed?: boolean;
  isLoading?: boolean;
};

type IPoweredByButton = {
  collapsed?: boolean;
  isHovered: boolean;
};

export const MainContainer = styled.section`
  display: flex;
`;

export const SidebarWrapper = styled.aside<ICollapsedElements>`
  ${({ theme, collapsed }) => {
    const { colors } = theme.foundations;
    return `
    background: ${collapsed ? colors.palette.neutral['200'] : colors.palette.neutral['100']};
    width:${collapsed ? '45px' : '221px'};
    border-right: ${collapsed ? 'none' : `1px solid ${colors.palette.neutral['300']}`};
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    position: relative;

  `;
  }}
`;

export const Dot = styled.div`
  ${({ theme }) => {
    const { colors, radii } = theme.foundations;
    return `
      position: absolute;
      width: ${radii.md};
      height: ${radii.md};
      left: calc(50% - 6px / 2);
      top: calc(50% - 6px / 2);
      background: ${colors.palette.accent};
      outline: 1px solid ${colors.palette.neutral['100']};
      border-radius:${radii.md};

      .sonar-wave {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: ${colors.palette.teal['300']};
        animation: sonarWave 2s linear infinite;
      }
      
      @keyframes sonarWave {
        from {
          opacity: 0.8;
        }
        to {
          transform: scale(3);
          opacity: 0;
        }
      }
  `;
  }}
`;

export const NotificationDot = styled.div`
  ${({ theme }) => {
    const { sizes } = theme.foundations;
    return `
    position: absolute;
    width: ${sizes['04']};
    height: ${sizes['04']};
    left: 15px;
    top: 2px;
  `;
  }}
`;

export const SidebarIcon = styled.div<ISidebarIcon>`
  ${({ theme, active }) => {
    const { sizes, colors, radii } = theme.foundations;
    return `
      display:flex;
      justify-content:center;
      align-items: center;
      width: ${sizes['07']};
      height: ${sizes['07']};
      border-radius:${radii.md};
      color: ${active ? colors.palette.neutral['50'] : colors.palette.neutral['600']};
      background-color: ${active ? colors.palette.neutral['600'] : 'inherit'};
      &:hover{
        background-color: ${colors.palette.neutral['300']};
        cursor: pointer;
        color: ${colors.palette.neutral['800']};
      }
      position:relative;
  `;
  }}
`;

export const SidebarItem = styled(Link)<ISidebarItem>`
  ${({ theme, collapsed, active }) => {
    const { colors, radii, spacing, sizes } = theme.foundations;
    return `
      display: flex;
      text-decoration: none;
      align-items: center;
      max-height: 48px;
      max-width:  ${collapsed ? '48px' : '196px'};
      width: ${collapsed ? 'auto' : '196px'};
      margin: ${collapsed ? `${spacing['02']}` : `0 ${spacing['03']}`};
      padding: ${collapsed ? '0' : spacing['02']};
      color: ${active ? colors.palette.neutral['800'] : colors.palette.neutral['600']};
      background-color: ${active ? colors.palette.neutral['200'] : 'inherit'};
      border-radius: ${radii.md};
      gap: ${collapsed ? '0' : `${spacing['02']}`};
      &:focus {
        outline: ${collapsed ? 'none' : `${sizes['01']} solid ${colors.palette.neutral['400']}`};
      }
      ${
        !collapsed
          ? `
          &:hover {
            background-color: ${colors.palette.neutral['300']};
            cursor: pointer;
            color: ${colors.palette.neutral['800']};
          }
      `
          : `{}`
      }
      &:hover{
        color: ${colors.palette.neutral['800']};
        background-color: ${active ? colors.palette.neutral['300'] : colors.palette.neutral['200']};
        ${SidebarIcon}{
        cursor: pointer;
        background-color: ${active ? colors.palette.neutral['600'] : 'inherit'};
        color: ${active ? colors.palette.neutral['50'] : colors.palette.neutral['800']};
        ${collapsed &&
          `
            &:hover {
              background-color: ${colors.palette.neutral['300']};
            }
        `}
        ${collapsed &&
          active &&
          `
            &:hover {
              background-color: ${colors.palette.neutral['700']};
              color: ${colors.palette.neutral['50']};
            }
        `}
        }
        
      }
    }
  `;
  }}
`;

export const SidebarItemButton = styled.button<ISidebarItem>`
  ${({ theme, collapsed, active }) => {
    const { colors, radii, spacing, sizes } = theme.foundations;
    return `
      display: flex;
      text-decoration: none;
      align-items: center;
      max-height: 48px;
      max-width:  ${collapsed ? '48px' : '196px'};
      width: ${collapsed ? 'auto' : '196px'};
      margin: ${collapsed ? `${spacing['02']}` : `0 ${spacing['03']}`};
      padding: ${collapsed ? '0' : spacing['02']};
      color: ${active ? colors.palette.neutral['800'] : colors.palette.neutral['600']};
      background-color: ${active ? colors.palette.neutral['200'] : 'inherit'};
      border-radius: ${radii.md};
      gap: ${collapsed ? '0' : `${spacing['02']}`};
      &:focus {
        outline: ${collapsed ? 'none' : `${sizes['01']} solid ${colors.palette.neutral['400']}`};
      }
      ${
        !collapsed
          ? `
          &:hover {
            background-color: ${colors.palette.neutral['300']};
            cursor: pointer;
            color: ${colors.palette.neutral['800']};
          }
      `
          : `{}`
      }
      &:hover{
        color: ${colors.palette.neutral['800']};
        background-color: ${active ? colors.palette.neutral['300'] : colors.palette.neutral['200']};
        ${SidebarIcon}{
        cursor: pointer;
        background-color: ${active ? colors.palette.neutral['600'] : 'inherit'};
        color: ${active ? colors.palette.neutral['50'] : colors.palette.neutral['800']};
        ${collapsed &&
          `
            &:hover {
              background-color: ${colors.palette.neutral['300']};
            }
        `}
        ${collapsed &&
          active &&
          `
            &:hover {
              background-color: ${colors.palette.neutral['700']};
              color: ${colors.palette.neutral['50']};
            }
        `}
        }
        
      }
    }
  `;
  }}
`;

export const SidebarButtonContainer = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
    display: flex;
    justify-content: end;
    z-index: 1;
    position: absolute;
    right: -${spacing['04']};
    bottom: 194px;
    margin-bottom:${spacing['04']};
  `;
  }}
`;

export const SidebarButton = styled.button<ICollapsedElements>`
  ${({ theme, collapsed }) => {
    const { sizes, colors, radii } = theme.foundations;
    return `
      display: flex;
      align-self: flex-end;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: ${sizes['07']};
      height: ${sizes['07']};
      background: ${colors.palette.neutral['200']};
      transform: ${collapsed ? 'none' : 'rotate(180deg)'};
      border: 1px solid ${colors.palette.neutral['300']};
      border-radius: ${radii.lg};
      z-index: 1;
      &:hover {
        background: ${colors.palette.neutral['50']};
        cursor: pointer;
      }
  `;
  }}
`;

export const SidebarItemContainer = styled.div`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `
      display: flex;
      flex-direction: column;
      gap: ${spacing['01']};
      margin-top: ${spacing['01']};
  `;
  }}
`;

export const SidebarSection = styled.div<ISidebarSection>`
  ${({ theme, hasFirm, collapsed, isLoading }) => {
    const { spacing } = theme.foundations;
    return `
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top:${isLoading || !hasFirm ? spacing['05'] : '0px'};
    ${
      collapsed
        ? `&::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    `
        : `{}`
    }
  `;
  }}
`;

export const SidebarItemName = styled.p`
  ${({ theme }) => {
    const { typography } = theme.foundations;
    return `
      font-family: ${typography.fonts.body};
      font-style: normal;
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['03']};
      line-height: 125%;
  `;
  }}
`;

export const SidebarFirm = styled.div<ICollapsedElements>`
  ${({ theme, collapsed }) => {
    const { typography, spacing, colors } = theme.foundations;
    return `
    display:flex;
    align-items: center;
    padding: ${
      collapsed ? `${spacing['04']} ${spacing['01']}` : `${spacing['04']} ${spacing['04']}`
    };
    gap: ${spacing['02']};
    color: ${colors.palette.neutral['600']};
    font-family: ${typography.fonts.body};
    font-style: normal;
    font-weight: ${typography.weights.medium};
    font-size: ${typography.sizes['05']};
    line-height: 150%;
`;
  }}
`;

export const SidebarFirmLogo = styled.div`
  ${({ theme }) => {
    const { sizes, radii, colors } = theme.foundations;
    return `
    width:${sizes['08']};
    height: ${sizes['08']};
    justify-content: center;
    align-items: center;
    display: flex;
    border: 1px solid ${colors.palette.neutral['300']};
    background: ${colors.palette.neutral['50']};
    border-radius: ${radii.sm};
    overflow: hidden;
  `;
  }}
`;

export const SidebarFirmName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 140px;
`;

export const SidebarDivider = styled.div`
  ${({ theme }) => {
    const { colors, spacing } = theme.foundations;
    return `
    border: 1px solid ${colors.palette.neutral['300']};
    margin: ${spacing['04']} ${spacing['02']};
    height: 1px;
  `;
  }}
`;
export const SidebarCategory = styled.div<ICollapsedElements>`
  ${({ theme }) => {
    const { colors, typography, spacing } = theme.foundations;
    return `
    color: ${colors.palette.neutral['500']};
    font-family: ${typography.fonts.body};
    font-style: normal;
    font-weight: ${typography.weights.medium};
    font-size: ${typography.sizes['02']};
    line-height: 15px;
    text-transform: capitalize;
    letter-spacing: 1px;
    padding: ${spacing['02']} ${spacing['04']};

  `;
  }}
`;

export const SidebarLinksContainer = styled.div<ICollapsedElements>`
  ${({ theme }) => {
    const { spacing } = theme.foundations;
    return `  
      display: flex;
      gap: ${spacing['02']};
      align-items: center;
      padding: ${spacing['04']};
      flex-wrap: wrap;
      `;
  }}
`;

export const SidebarLink = styled.button`
  ${({ theme }) => {
    const { colors } = theme.foundations;
    return ` 
      background: ${colors.palette.transparent};
    `;
  }}
`;

export const SidebarLinkButtonIcon = styled.a`
  ${({ theme }) => {
    const { colors, spacing, sizes, radii } = theme.foundations;
    return `  
      border: 1px solid ${colors.palette.neutral['300']};
      padding: ${spacing['01']};
      width: ${sizes['07']};
      height: ${sizes['07']};
      border-radius: ${radii.md};      
      background: ${colors.palette.transparent};
      color: ${colors.palette.neutral['600']};
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover{
        cursor:pointer;
        color: ${colors.palette.neutral['700']};
        border-color: ${colors.palette.neutral['700']};
      }
      &:focus{
        outline: ${sizes['01']} solid ${colors.palette.neutral['400']};
        color: ${colors.palette.neutral['700']};
        border: none;
      }
    `;
  }}
`;

export const SidebarFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto;
`;

export const SidebarPowerBySection = styled.div<ICollapsedElements>`
  ${({ theme, collapsed }) => {
    const { spacing } = theme.foundations;
    return `
      align-items: center;
      justify-content: center;
      display: flex;
      height: 130px;
      padding:${
        collapsed
          ? `${spacing['05']} ${spacing['02']} ${spacing['10']}`
          : `${spacing['05']} ${spacing['06']} ${spacing['10']} `
      }
  `;
  }}
`;

export const SidebarPowerByTitle = styled.p`
  ${({ theme }) => {
    const { typography, spacing } = theme.foundations;
    return `
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-style: normal;
      line-height: 18px;
      gap: ${spacing['01']};
      font-family: ${typography.fonts.body};
      font-weight: ${typography.weights.regular};
      font-size: ${typography.sizes['02']};
    `;
  }}
`;

export const SidebarPoweredByLogo = styled.button<IPoweredByButton>`
  ${({ theme, isHovered, collapsed }) => {
    const { colors, radii, spacing } = theme.foundations;
    return `
      display: flex;
      align-self: center;
      flex-direction: column;
      border-radius: ${radii.sm};
      color:  ${colors.palette.neutral['500']};
      background-color: ${isHovered ? `${colors.palette.neutral['200']}` : 'inherit'};
      border: ${collapsed ? 'none' : `0.5px solid ${colors.palette.neutral[300]}`};
      padding: ${spacing['02']};
      opacity: 80%;
      &:hover {
        cursor: pointer;
        opacity: 100%;
        border: none;
      }
    `;
  }}
`;

export const SkeletonWrapper = styled.div`
  gap: 24px;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
`;

export const FirmSkeletonWrapper = styled.div`
  margin-bottom: 16px;
  align-items: center;
  gap: 8px;
  align-self: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;
