import { DealFilterEnum, sortDirection } from '../types';

export const MODALS_HEIGHT_CLOSE = 100;

export const TEXT_AREA_MAX_VALUE = 700;

export enum DealInformation {
  DEAL_NAME = 'Deal name',
  PUBLISHED_DATE = 'Published date',
  DAYS_ON_MARKET = 'Days on market',
  PRIMARY_INDUSTRY = 'Primary industry',
  SECONDARY_INDUSTRY = 'Secondary industry',
  SECTOR = 'Sector',
  INDUSTRY = 'Industry',
  TAIL_PERIOD_EXPIRATION = 'Tail period expiration',
  RETAINER_AMOUNT = 'Retainer amount',
  LOCATION = 'Location',
  DEAL_TYPE = 'Deal type',
  DEAL_STAGE = 'Deal stage',
  ORIGINATED_BY = 'Originated by',
  EXPECTED_DEAL_SIZE = 'Expected deal size',
  SUCCESS_FEE = 'Success fee',
  NO_DESCRIPTION = 'No description provided.',
  EMPTY_VALUE = 'Not disclosed',
}

export enum FundInformation {
  FUND_NAME = 'Fund name',
  FUND_SIZE = 'Fund size',
  EXPECTED_RETURN = 'Expected return',
  EXPECTED_IRR = 'Expected IRR',
  TERM = 'Term',
  MANAGEMENT_FEE = 'Management fee',
}

export const DefaultMMSort = {
  sortName: DealFilterEnum.PUBLISHED_AT.toString(),
  sortType: sortDirection.ASC,
};

export const MAX_COLLAB_PER_FIRM = 10;

export const CONVERSATION_POLLING_INTERVAL = 60000;
