// vendors
import React, { useContext } from 'react';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-modern-calendar-datepicker';
import get from 'lodash/get';

// Context
import { UserContext } from 'app/users/context/UserContext';

// components
import {
  FormGroup,
  Input,
  OptionCard,
  TextArea,
  Select,
  SingleOptionRadioButtons,
  VStack,
} from '../../../../../common/components';
import { InputLabelContainer, SuccessFeeContainer } from '../DealsStyled';

// constants
import {
  AdditionalInfoTexts,
  NDMarketplaceTexts,
  NDPrimaryInfoTexts,
} from '../../../../components/forms/NewDealForm/NewDealFormTexts';
import {
  DEAL_FORM_LABELS,
  DEAL_STATUS_OPTIONS,
  DEAL_TARGET_INVESTOR_OPTIONS,
  DEAL_TYPES_OPTIONS,
} from '../../../../../utils/helpers/deal';
import {
  DEAL_INDUSTRY_TYPES_OPTIONS,
  getIndustryTypeSubOptions,
} from '../../../../../utils/helpers/dealIndustryTypes';
import { MAX_DEAL_EARNINGS } from '../../../../../utils/constants/app/appConstants';
import { tailPeriodOptions } from '../../../../../navigation/GlobalSidebar/components/SettingsModal/components/MarketplaceSettings/HelpersMarketplaceSettings';
import { UpdateDealForm } from '../../../../../navigation/GlobalSidebar/components/SettingsModal/components/DealInformationSettings/TypedDealInformationSettings';
import { minimumDate, maximumDate } from 'app/utils/constants/app/appConstants';

// icons
import { DollarIcon, OpenEyeIcon, ThunderIcon } from '../../../../../common/icons';

// helpers
import { formControlChangeHandlers } from '../../../../../compliance/components/FormControlChangeHandlers/FormControlChangeHandlers';
import { formatDateInput } from '../../../../../utils/helpers/helpers';

// Handler
import { FormikStub } from '../../../../../utils/hooks/useForm';
import { GET_USER_PROFILE_QUERY_profile_firms } from 'api/graphql/users/types/GET_USER_PROFILE_QUERY';

const sanitizeInputValues = (val: any) => {
  if (!val) return '';
  if (typeof val !== 'string' && typeof val !== 'number') return '';
  return val;
};

export interface editDealFormProps {
  editHandler: {
    form: FormikStub<UpdateDealForm>;
    isDealOwner: boolean;
    hasActiveFirm: boolean;
    hasEnoughMembersToShare: boolean | null;
    isStatusDDClosing: boolean;
    currentFirm: GET_USER_PROFILE_QUERY_profile_firms | null;
  };
}

export const EditDealForm = ({ editHandler }: editDealFormProps) => {
  const { marketplaceAccessEnabled } = useContext(UserContext);
  const {
    form,
    isDealOwner,
    isStatusDDClosing,
    hasActiveFirm,
    hasEnoughMembersToShare,
    currentFirm,
  } = editHandler;
  const { values, setFieldValue } = form;
  const {
    handleSelectChange,
    handleFieldChange,
    handleYesNoChange,
    handleNumberInputChange,
    handleDateChange,
  } = formControlChangeHandlers<UpdateDealForm>(setFieldValue);

  const ExpectedDealSizeInput = React.useMemo(
    () => (props: any) => <Input leftIcon={<DollarIcon />} {...props} />,
    []
  );
  const MinFeeInput = React.useMemo(
    () => (props: any) => <Input prefix="Min" suffix="%" {...props} />,
    []
  );
  const MaxFeeInput = React.useMemo(
    () => (props: any) => <Input prefix="Max" suffix="%" {...props} />,
    []
  );

  const isCollaborative = values.marketplaceDealType === 'collab';
  return (
    <>
      <FormGroup
        label={NDPrimaryInfoTexts.NAME}
        showCharCount
        helperText={NDPrimaryInfoTexts.NAME_DESCRIPTION}
        required
      >
        {(ref, { onValueSet }) => (
          <Input
            ref={ref as any}
            id="newDealName"
            maxLength={120}
            name="newDealName"
            placeholder={NDPrimaryInfoTexts.NAME_PLACEHOLDER}
            value={sanitizeInputValues(values.name)}
            onChange={handleFieldChange('name')}
            onValueSet={onValueSet}
          />
        )}
      </FormGroup>
      <FormGroup label="Project name" optional>
        <Input
          id="projectName"
          name="projectName"
          value={sanitizeInputValues(values.projectName)}
          onChange={handleFieldChange('projectName')}
        />
      </FormGroup>
      <FormGroup label={NDPrimaryInfoTexts.TYPE} required>
        <Select
          options={DEAL_TYPES_OPTIONS}
          inputId="dealType"
          name="dealType"
          value={values.type}
          onChange={handleSelectChange('type')}
          maxMenuHeight={200}
          placeholder={NDPrimaryInfoTexts.TYPE_PLACEHOLDER}
          isSearchable={false}
        />
      </FormGroup>

      <FormGroup label={NDPrimaryInfoTexts.LEGAL_NAME_LABEL} required>
        <Input
          id="legalName"
          name="legalName"
          placeholder={NDPrimaryInfoTexts.LEGAL_NAME_PLACEHOLDER}
          value={sanitizeInputValues(values.companyName)}
          onChange={handleFieldChange('companyName')}
        />
      </FormGroup>
      <FormGroup label="Client headquarters location (address)" required>
        <Input
          id="headquarterAddress"
          name="headquarterAddress"
          placeholder="Enter address"
          value={sanitizeInputValues(values.companyAddress)}
          onChange={handleFieldChange('companyAddress')}
        />
      </FormGroup>
      <FormGroup label={NDPrimaryInfoTexts.COUNTRY_LABEL} required>
        <Input
          id="country"
          name="country"
          placeholder={NDPrimaryInfoTexts.COUNTRY_PLACEHOLDER}
          value={sanitizeInputValues(values.country)}
          onChange={handleFieldChange('country')}
        />
      </FormGroup>
      <FormGroup label={NDPrimaryInfoTexts.STATE_LABEL} required>
        <Input
          id="state"
          name="state"
          placeholder={NDPrimaryInfoTexts.STATE_PLACEHOLDER}
          value={sanitizeInputValues(values.state)}
          onChange={handleFieldChange('state')}
        />
      </FormGroup>
      <FormGroup label={NDPrimaryInfoTexts.ZIPCODE_LABEL} required>
        <Input
          id="zipCode"
          name="zipCode"
          placeholder={NDPrimaryInfoTexts.ZIPCODE_PLACEHOLDER}
          value={sanitizeInputValues(values.zipCode)}
          onChange={handleFieldChange('zipCode')}
        />
      </FormGroup>
      {isDealOwner && hasActiveFirm && hasEnoughMembersToShare && (
        <FormGroup
          label={`Do you want to share the deal with the firm? ${
            currentFirm ? `(${currentFirm.name})` : ''
          }`}
          required
        >
          <SingleOptionRadioButtons
            strictValueOnChange
            id="private_question"
            name="private_question"
            value={values.private}
            onChange={handleYesNoChange('private')}
          />
        </FormGroup>
      )}
      {marketplaceAccessEnabled && (
        <FormGroup label={NDMarketplaceTexts.TITLE}>
          <VStack
            spacing={16}
            style={{ marginTop: '16px' }}
            onChange={handleFieldChange('marketplaceDealType')}
          >
            <OptionCard
              title={NDMarketplaceTexts.NON_COLLABORATIVE_TITLE}
              subtitle={NDMarketplaceTexts.NON_COLLABORATIVE_SUBTITLE}
              icon={<OpenEyeIcon width="20px" height="16px" />}
              name="collaboration"
              value="non-collab"
              checked={values.marketplaceDealType === 'non-collab'}
            />
            <OptionCard
              title={NDMarketplaceTexts.COLLABORATIVE_TITLE}
              subtitle={NDMarketplaceTexts.COLLABORATIVE_SUBTITLE}
              icon={<ThunderIcon width="16px" height="20px" />}
              name="collaboration"
              value="collab"
              checked={values.marketplaceDealType === 'collab'}
            />
          </VStack>
        </FormGroup>
      )}

      <FormGroup label={AdditionalInfoTexts.DEAL_STAGE_LABEL} required>
        <Select
          options={DEAL_STATUS_OPTIONS}
          inputId="dealStatus"
          name="dealStatus"
          value={values.dealStatus}
          onChange={handleSelectChange('dealStatus')}
          maxMenuHeight={200}
          isSearchable={false}
        />
      </FormGroup>
      {isStatusDDClosing && (
        <FormGroup label="Whether a copy of LOI/Term sheet of this deal has been provided to compliance at compliance@finalis.com?">
          <SingleOptionRadioButtons
            strictValueOnChange
            value={values.loiProvided}
            id="loiProvided"
            name="loiProvided"
            onChange={handleYesNoChange('loiProvided')}
          />
        </FormGroup>
      )}
      {isStatusDDClosing && (
        <FormGroup label="Estimated Closing date">
          <DatePicker
            value={values.closingDate}
            minimumDate={minimumDate}
            maximumDate={maximumDate}
            formatInputText={() => formatDateInput(values.closingDate)}
            calendarPopperPosition="bottom"
            onChange={handleDateChange('closingDate')}
            inputPlaceholder="Select a date"
          />
        </FormGroup>
      )}
      <FormGroup label={AdditionalInfoTexts.P_INDUSTRY_LABEL} required>
        <Select
          placeholder={AdditionalInfoTexts.P_INDUSTRY_PLACEHOLDER}
          options={DEAL_INDUSTRY_TYPES_OPTIONS}
          inputId="primaryIndustry"
          name="primaryIndustry"
          value={values.industryType}
          onChange={(evt: any) => {
            handleSelectChange('industryType')(evt);
            handleSelectChange('industryTypeSubOptions')(null);
          }}
          maxMenuHeight={200}
          isSearchable={false}
        />
      </FormGroup>
      {get(values, 'industryType.value', '') && (
        <FormGroup label={AdditionalInfoTexts.S_INDUSTRY_LABEL} optional>
          <Select
            placeholder={AdditionalInfoTexts.S_INDUSTRY_PLACEHOLDER}
            options={getIndustryTypeSubOptions(get(values, 'industryType.value', null))}
            inputId="secondaryIndustry"
            name="secondaryIndustry"
            value={values.industryTypeSubOptions}
            onChange={handleSelectChange('industryTypeSubOptions')}
            maxMenuHeight={200}
            isSearchable={false}
          />
        </FormGroup>
      )}
      <FormGroup
        label={AdditionalInfoTexts.RETAINER_QUESTION}
        required={isCollaborative}
        optional={!isCollaborative}
      >
        <SingleOptionRadioButtons
          strictValueOnChange
          value={values.monthlyRetainer}
          id="monthlyRetainer"
          name="monthlyRetainer"
          onChange={handleYesNoChange('monthlyRetainer')}
        />
      </FormGroup>
      {values.monthlyRetainer && (
        <FormGroup label="Retainer amount">
          <NumberFormat
            id="retainerAmount"
            name="retainerAmount"
            value={sanitizeInputValues(values.retainerAmount)}
            onValueChange={handleNumberInputChange('retainerAmount')}
            thousandSeparator
            decimalSeparator={false}
            allowNegative={false}
            customInput={ExpectedDealSizeInput}
          />
        </FormGroup>
      )}
      <FormGroup
        label={AdditionalInfoTexts.DEAL_SIZE_LABEL}
        required={isCollaborative}
        optional={!isCollaborative}
      >
        <NumberFormat
          id="expectedSize"
          name="expectedSize"
          placeholder={AdditionalInfoTexts.DEAL_SIZE_PLACEHOLDER}
          value={sanitizeInputValues(values.expectedSize)}
          onValueChange={handleNumberInputChange('expectedSize')}
          thousandSeparator
          decimalSeparator={false}
          allowNegative={false}
          customInput={ExpectedDealSizeInput}
        />
      </FormGroup>
      <FormGroup
        label={AdditionalInfoTexts.SUCCESS_FEE_LABEL}
        required={isCollaborative}
        optional={!isCollaborative}
      >
        <SuccessFeeContainer>
          <InputLabelContainer style={{ marginRight: 10 }}>
            <NumberFormat
              id="minFee"
              name="minFee"
              placeholder={AdditionalInfoTexts.SUCCESS_FEE_PLACEHOLDER}
              value={values.minFee}
              onValueChange={handleNumberInputChange('minFee')}
              thousandSeparator
              decimalSeparator={false}
              allowNegative={false}
              customInput={MinFeeInput}
            />
          </InputLabelContainer>
          <InputLabelContainer>
            <NumberFormat
              id="maxFee"
              name="maxFee"
              placeholder={AdditionalInfoTexts.SUCCESS_FEE_PLACEHOLDER}
              value={values.maxFee}
              onValueChange={handleNumberInputChange('maxFee')}
              displayType="input"
              thousandSeparator
              decimalSeparator={false}
              allowNegative={false}
              customInput={MaxFeeInput}
            />
          </InputLabelContainer>
        </SuccessFeeContainer>
      </FormGroup>
      <FormGroup
        label={AdditionalInfoTexts.TAIL_PERIOD_LABEL}
        required={isCollaborative}
        optional={!isCollaborative}
      >
        <Select
          options={tailPeriodOptions}
          placeholder={AdditionalInfoTexts.TAIL_PERIOD_PLACEHOLDER}
          inputId="tailPeriodDuration"
          name="tailPeriodDuration"
          value={values.tailPeriodDuration}
          onChange={handleSelectChange('tailPeriodDuration')}
          maxMenuHeight={200}
          isSearchable={false}
        />
      </FormGroup>

      {/* The following questions aren't in the new new deal form! */}
      <FormGroup label={DEAL_FORM_LABELS.PUBLICITY_TRADED_COMPANY} optional>
        <SingleOptionRadioButtons
          strictValueOnChange
          id="publiclyTradedCompany"
          name="publiclyTradedCompany"
          value={values.publiclyTradedCompany}
          onChange={handleYesNoChange('publiclyTradedCompany')}
        />
      </FormGroup>

      {values.publiclyTradedCompany && (
        <FormGroup label={DEAL_FORM_LABELS.TICKER_EXCHANGE} optional>
          <Input
            id="ticker"
            name="ticker"
            value={sanitizeInputValues(values.ticker)}
            onChange={handleFieldChange('ticker')}
          />
        </FormGroup>
      )}
      <FormGroup label={AdditionalInfoTexts.COMISSION_LABEL} optional>
        <NumberFormat
          id="expectedCommissions"
          name="expectedCommissions"
          min="0"
          max={MAX_DEAL_EARNINGS}
          placeholder={AdditionalInfoTexts.COMISSION_PLACEHOLDER}
          onValueChange={handleNumberInputChange('expectedCommissions')}
          value={sanitizeInputValues(values.expectedCommissions)}
          thousandSeparator
          decimalSeparator={false}
          allowNegative={false}
          customInput={ExpectedDealSizeInput}
        />
      </FormGroup>
      <FormGroup label={AdditionalInfoTexts.TARGET_INV_LABEL} optional>
        <Select
          options={DEAL_TARGET_INVESTOR_OPTIONS}
          placeholder={AdditionalInfoTexts.TARGET_INV_PLACEHOLDER}
          inputId="targetInvestors"
          name="targetInvestors"
          value={values.targetInvestors}
          onChange={handleSelectChange('targetInvestors')}
          maxMenuHeight={200}
          isSearchable={false}
        />
      </FormGroup>

      <FormGroup label={DEAL_FORM_LABELS.INITIAL_DUE_DILIGENCE} optional>
        <TextArea
          rows={3}
          name="initialDueDiligence"
          onChange={handleFieldChange('initialDueDiligence')}
          value={sanitizeInputValues(values.initialDueDiligence)}
        />
      </FormGroup>

      <FormGroup label={AdditionalInfoTexts.CONFLICT_QUESTION} optional>
        <TextArea
          rows={3}
          name="potentialConflicts"
          onChange={handleFieldChange('potentialConflicts')}
          value={sanitizeInputValues(values.potentialConflicts)}
        />
      </FormGroup>

      <FormGroup label={AdditionalInfoTexts.FOREIGN_SOLICITATION_QUESTION} optional>
        <SingleOptionRadioButtons
          strictValueOnChange
          value={values.foreignSolicitation}
          id="foreignSolicitation"
          name="foreignSolicitation"
          onChange={handleYesNoChange('foreignSolicitation')}
        />
      </FormGroup>
    </>
  );
};
