import React, { useState, useEffect, useContext } from 'react';
import { Formik } from 'formik';
import { ValueType } from 'react-select/src/types';
import _get from 'lodash/get';
import DatePicker, { utils as calendarUtils } from 'react-modern-calendar-datepicker';
import { SelectComponentsProps } from 'react-select/src/Select';
import { useMutation } from '@apollo/client';
import NumberFormat from 'react-number-format';
import { TextArea } from '@dealsyte/fns-design-system';
import { Tooltip, InfoIcon } from '@dealsyte/poki';

// Context
import { AlertContext } from '../../../../notifications/context/Alert/Alert';

// Helpers
import {
  getStyles,
  buildInitialValues,
  buildPayload,
  COUNTRIES_WITH_STATES,
} from './EditDealSideDrawerHelper';
import {
  DEAL_FORM_LABELS,
  DEAL_STATUS_OPTIONS,
  DEAL_TYPES_OPTIONS,
} from '../../../../utils/helpers/deal';
import {
  DEAL_INDUSTRY_TYPES_OPTIONS,
  getIndustryTypeSubOptions,
} from '../../../../utils/helpers/dealIndustryTypes';
import {
  getTailPeriodLabel,
  tailPeriodOptions,
} from '../../../../navigation/GlobalSidebar/components/SettingsModal/components/MarketplaceSettings/HelpersMarketplaceSettings';
import { formatDateInput, maxPercentValueAllowed } from '../../../../utils/helpers/helpers';

//Constants
import { minimumDate, maximumDate } from 'app/utils/constants/app/appConstants';

// Components
import Select from '../../../../common/components/Select/PortalSelect';
import { Button } from '../../../../common/components/Button/Button';
import { Input } from '../../../../common/components/Input/Input';
import MMRadioButton from '../../mmRadioButton/mmRadioButton';
import {
  withScrollToError,
  ScrollToError,
} from '../../../../common/components/ScrollToError/ScrollToError';
import { HStack, OptionCard, SideDrawer, VStack } from '../../../../common/components';

// Hooks
import useStateModal from '../../../../utils/hooks/useStateModal';
import useGoogleAnalytics from '../../../../utils/hooks/useGoogleAnalytics';

// Mutations
import { UPDATE_MARKETPLACE_DEAL } from '../../../../../api/graphql/marketplace/mutations';

// Icons
import {
  CalendarIcon,
  ChatBubbleInfoIcon,
  OpenEyeIcon,
  ThunderIcon,
} from '../../../../common/icons';
import DollarIcon from '../../../assets/dollarIcon';
import CircleAlarmIcon from '../../../assets/circleAlarmIcon';

// Constants
import { TEXT_AREA_MAX_VALUE } from '../../../constants/index';
import { COUNTRY_OPTIONS, getCountrySubOptions } from '../../../../utils/constants/dealFormOptions';
import { GA_ACTION, GA_CATEGORY } from '../../../../utils/types/types';

// Validation Schema
import { validationSchema } from './EditDealSideDrawerFormValidation';
import { NDMarketplaceTexts } from '../../../../compliance/components/forms/NewDealForm/NewDealFormTexts';
import { MarketplaceDealType } from '../../../../marketplace/types';

// AppStorage
import AppStorage, { EMAIL_KEY } from '../../../../appStorage';

// Style
import {
  SeparatorLine,
  SelectTitle,
  BodyContainer,
  FieldContainer,
  YesNoRadioButtonContainer,
  GrossFeeInputContainer,
  DatePickerConteiner,
  ErrorFieldContainer,
  ButtonContainer,
} from './StyledEditDealSideDrawer';
import useGoogleAnalytics4 from '../../../../utils/hooks/useGoogleAnalytics4';

interface ISelectProps {
  title: string;
  required?: boolean;
  error?: React.ReactNode;
}

interface ITitleProps {
  title: string;
  required?: boolean;
  tooltipText?: string;
}

const EditDealSideDrawer = ({ scrollToField }: any) => {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const { hide, modalState } = useStateModal('editDealSideDrawer');
  const { show, props: modalProps } = modalState;
  const [initialValues, setInitialValues] = useState<any>(buildInitialValues());
  const { dealSelected, refetch } = modalProps;
  const isEmailDefined = AppStorage.getItem(EMAIL_KEY);
  const email: string = isEmailDefined ? isEmailDefined.toString() : '';
  const { trackSingleEvent } = useGoogleAnalytics();
  const { trackSingleEvent: trackSingleEventG4 } = useGoogleAnalytics4();

  useEffect(() => {
    if (show) {
      setInitialValues(buildInitialValues(dealSelected));
    }
  }, [show]);

  const [updateMarketplaceDeal, { loading }] = useMutation(UPDATE_MARKETPLACE_DEAL, {
    onCompleted() {
      const toastMessage = dealSelected ? 'Deal updated successfully' : 'Deal Creation Success';
      showSuccessToast({ title: toastMessage });
      hide();
      refetch();
    },
    onError(error: any) {
      const toastMessage = dealSelected ? 'Deal Update Error' : 'Deal Creation Error';
      showErrorToast({ title: toastMessage, description: '' }, error);
    },
  });

  const handleClose = (resetForm: () => void) => () => {
    resetForm();
    hide();
  };

  const handleUpdateDeal = (values: any) => {
    if (dealSelected) {
      const dealPayload = {
        ...buildPayload(values),
        id: _get(dealSelected, 'id'),
      };

      updateMarketplaceDeal({
        variables: {
          marketplaceDeal: dealPayload,
        },
      }).then(() => {
        trackSingleEvent(GA_CATEGORY.UI, GA_ACTION.EDIT_MM_DEAL, '', {
          dimension1: email, // email id
          dimension2: _get(dealSelected, 'marketplaceDealId'), // marketplace_id
        });
        trackSingleEventG4(GA_CATEGORY.UI, GA_ACTION.EDIT_MM_DEAL, undefined, {
          dimension1: email, // email id
          dimension2: _get(dealSelected, 'marketplaceDealId'), // marketplace_id
        });
      });
    }
  };

  const FormFieldTitle = (titleProps: ITitleProps) => {
    const { title, required, tooltipText } = titleProps;
    return (
      <SelectTitle>
        <p className="field-title">{title}</p>
        {tooltipText && (
          <Tooltip
            message={tooltipText}
            style={{ padding: '8px', background: '#1D1B2C', zIndex: 999, fontFamily: 'Effra' }}
          >
            <InfoIcon style={{ paddingLeft: 5 }} />
          </Tooltip>
        )}
        <p className="required-field">{required ? '(required)' : '(optional)'}</p>
      </SelectTitle>
    );
  };

  const SelectField = (selectProps: ISelectProps & SelectComponentsProps) => {
    const { title, required, name, menuPlacement, error } = selectProps;

    return (
      <>
        <FormFieldTitle title={title} required={required} />
        <Select
          portaled
          inputId={name}
          name={name}
          menuPlacement={menuPlacement || 'auto'}
          {...selectProps}
        />
        <ErrorField error={error} />
      </>
    );
  };

  const ErrorField = ({ error }: { error?: React.ReactNode }) =>
    error ? (
      <ErrorFieldContainer>
        <CircleAlarmIcon />
        <p>{error}</p>
      </ErrorFieldContainer>
    ) : null;

  const MinFeeInput = React.useMemo(
    () => (props: any) => <Input prefix="Min" suffix="%" {...props} />,
    []
  );
  const MaxFeeInput = React.useMemo(
    () => (props: any) => <Input prefix="Max" suffix="%" {...props} />,
    []
  );

  const ExpectedDealSizeInput = React.useMemo(
    () => (props: any) => <Input leftIcon={<DollarIcon />} {...props} />,
    []
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        handleUpdateDeal(values);
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        setFieldValue,
        values,
        errors,
        touched,
        handleSubmit,
        resetForm,
        setFieldTouched,
        handleChange,
        dirty,
      }) => (
        <SideDrawer
          show={show}
          onClose={handleClose(resetForm)}
          title="Edit deal"
          promptConfig={{
            uiConfig: config => ({
              ...config,
              title: (
                <HStack spacing={16} alignItems="center">
                  <ChatBubbleInfoIcon color="#2C6EF2" />
                  <span> Discarding this message?</span>
                </HStack>
              ),
              description: 'Closing this window will lose the text you were writing.',
              confirmLabel: 'Discard message',
              declineLabel: 'Continue editing',
            }),
          }}
          promptOnClose={dirty}
          closeWhenClickOutside={false}
          footerContent={({ onClose: promptClose }) => (
            <ButtonContainer>
              <Button className="filter-button cancel" onClick={promptClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                className="filter-button"
                disabled={loading}
                onClick={event => {
                  event.preventDefault();
                  scrollToField(errors);
                  handleSubmit();
                }}
              >
                Apply changes
              </Button>
            </ButtonContainer>
          )}
        >
          <BodyContainer>
            <ScrollToError field="dealName">
              <FieldContainer>
                <FormFieldTitle
                  title="Deal name"
                  required
                  tooltipText="Deal name visible only to you"
                />
                <Input
                  name="dealName"
                  value={values.dealName}
                  onChange={handleChange('dealName')}
                  placeholder="Deal name"
                  onBlur={event => {
                    setFieldValue('dealName', event.target.value.trim());
                    setFieldTouched('dealName');
                  }}
                  error={!!(touched.dealName && errors.dealName)}
                />
                <ErrorField error={touched.dealName && errors.dealName} />
              </FieldContainer>
            </ScrollToError>
            <ScrollToError field="transactionType">
              <FieldContainer style={{ paddingBottom: '32px' }}>
                <SelectField
                  error={touched.transactionType && errors.transactionType}
                  placeholder="Select a transaction type"
                  name="transactionType"
                  options={DEAL_TYPES_OPTIONS}
                  title={DEAL_FORM_LABELS.DEAL_TYPE}
                  required
                  value={values.transactionType}
                  errorStyle={getStyles(errors, 'transactionType')}
                  onChange={(
                    inSelectTransactionType: ValueType<{ value: String; label: String }>
                  ) => {
                    handleChange('transactionType')(inSelectTransactionType);
                    setFieldTouched('transactionType');
                  }} // TODO: use the hook formControlChangeHandler to get the needed handlers
                />
              </FieldContainer>
            </ScrollToError>
            <SeparatorLine />
            <>
              <p className="title">Collaboration</p>
              <p className="sub-title">
                These options specify whether or not Marketplace users can collaborate on your deal.
                If you decide to collaborate, our Connect team may request additional details to
                improve matchmaking opportunities.
              </p>
              <VStack spacing={16} style={{ marginTop: '16px' }}>
                <OptionCard
                  name="CollaborationStatus"
                  title={NDMarketplaceTexts.NON_COLLABORATIVE_TITLE}
                  subtitle="Only displays your deal information"
                  icon={<OpenEyeIcon width="20px" height="16px" />}
                  value={MarketplaceDealType.NonCollaborative}
                  checked={values.marketplaceDealType === MarketplaceDealType.NonCollaborative}
                  onChange={handleChange('marketplaceDealType')}
                />
                <OptionCard
                  name="CollaborationStatus"
                  title={NDMarketplaceTexts.COLLABORATIVE_TITLE}
                  subtitle={NDMarketplaceTexts.COLLABORATIVE_SUBTITLE}
                  icon={<ThunderIcon width="16px" height="20px" />}
                  value={MarketplaceDealType.Collaborative}
                  checked={values.marketplaceDealType === MarketplaceDealType.Collaborative}
                  onChange={handleChange('marketplaceDealType')}
                />
              </VStack>
            </>
            <SeparatorLine />
            <p className="title">Additional information</p>
            <p className="sub-title">
              To enhance your deal presentation, review and update the following information:
            </p>
            <ScrollToError field="country">
              <FieldContainer>
                <SelectField
                  error={touched.country && errors.country}
                  placeholder="Select a country"
                  name="country"
                  options={COUNTRY_OPTIONS}
                  title="Country"
                  required
                  value={values.country}
                  errorStyle={touched.country ? getStyles(errors, 'country') : {}}
                  onChange={(inSelectCountry: ValueType<{ value: String; label: String }>) => {
                    if (values.country !== inSelectCountry) {
                      handleChange('country')(inSelectCountry);
                      handleChange('state')(null);
                    } else {
                      handleChange('country')(inSelectCountry);
                    }
                  }}
                  onBlur={() => setFieldTouched('country')}
                />
              </FieldContainer>
            </ScrollToError>
            {values.country &&
              !!COUNTRIES_WITH_STATES.find(value => value === values.country.value) && (
                <ScrollToError field="state">
                  <FieldContainer>
                    <SelectField
                      error={touched.state && errors.state}
                      placeholder="Select a state"
                      name="state"
                      options={getCountrySubOptions(_get(values, 'country.value', null))}
                      title={DEAL_FORM_LABELS.STATE}
                      required
                      value={values.state}
                      errorStyle={touched.state ? getStyles(errors, 'state') : {}}
                      onChange={(inSelectState: ValueType<{ value: String; label: String }>) => {
                        handleChange('state')(inSelectState);
                      }}
                      onBlur={() => {
                        setFieldTouched('state');
                      }}
                    />
                  </FieldContainer>
                </ScrollToError>
              )}
            <ScrollToError field="dealStage">
              <FieldContainer>
                <SelectField
                  error={errors.dealStage}
                  placeholder="Select a deal stage"
                  name="dealStage"
                  options={DEAL_STATUS_OPTIONS}
                  title="Deal stage"
                  required
                  value={values.dealStage}
                  errorStyle={getStyles(errors, 'dealStage')}
                  onChange={(inSelectStage: ValueType<{ value: String; label: String }>) => {
                    handleChange('dealStage')(inSelectStage);
                  }}
                />
              </FieldContainer>
            </ScrollToError>
            <ScrollToError field="primaryIndustry">
              <FieldContainer>
                <SelectField
                  error={touched.primaryIndustry && errors.primaryIndustry}
                  placeholder="Select a primary industry"
                  name="primaryIndustry"
                  options={DEAL_INDUSTRY_TYPES_OPTIONS}
                  title="Primary industry"
                  required
                  value={values.primaryIndustry}
                  errorStyle={touched.primaryIndustry ? getStyles(errors, 'primaryIndustry') : {}}
                  onChange={(
                    inSelectPrimaryIndustry: ValueType<{ value: String; label: String }>
                  ) => {
                    if (values.primaryIndustry !== inSelectPrimaryIndustry) {
                      handleChange('primaryIndustry')(inSelectPrimaryIndustry);
                      handleChange('secondaryIndustry')(null);
                    } else {
                      handleChange('primaryIndustry')(inSelectPrimaryIndustry);
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched('primaryIndustry');
                  }}
                />
              </FieldContainer>
            </ScrollToError>
            <ScrollToError field="secondaryIndustry">
              <FieldContainer>
                <SelectField
                  error={touched.secondaryIndustry && errors.secondaryIndustry}
                  placeholder="Select a secondary industry"
                  name="secondaryIndustry"
                  options={getIndustryTypeSubOptions(_get(values, 'primaryIndustry.value', null))}
                  title="Secondary industry"
                  required={values.marketplaceDealType === MarketplaceDealType.Collaborative}
                  value={values.secondaryIndustry}
                  errorStyle={
                    touched.secondaryIndustry ? getStyles(errors, 'secondaryIndustry') : {}
                  }
                  onChange={(
                    inSelectSecondaryIndustry: ValueType<{ value: String; label: String }>
                  ) => {
                    handleChange('secondaryIndustry')(inSelectSecondaryIndustry);
                  }}
                  onBlur={() => {
                    setFieldTouched('secondaryIndustry');
                  }}
                />
              </FieldContainer>
            </ScrollToError>
            <ScrollToError field="isRetainer">
              <FieldContainer>
                <FormFieldTitle
                  title="Does the deal have a Monthly Retainer?"
                  required={values.marketplaceDealType === MarketplaceDealType.Collaborative}
                />
                <YesNoRadioButtonContainer>
                  <MMRadioButton
                    name="isRetainer"
                    value="yes"
                    checked={values.isRetainer}
                    onClick={() => {
                      handleChange('isRetainer')(!values.isRetainer ? true : null);
                      if (values.isRetainer) {
                        handleChange('retainerAmount')(null);
                      }
                      setFieldTouched('isRetainer');
                    }}
                  />
                  <p>Yes</p>
                  <MMRadioButton
                    name="isRetainer"
                    value="no"
                    checked={values.isRetainer === false}
                    onClick={() => {
                      handleChange('isRetainer')(values.isRetainer !== false ? false : null);
                      if (values.isRetainer !== false) {
                        handleChange('retainerAmount')(0);
                      } else {
                        handleChange('retainerAmount')(null);
                      }
                      setFieldTouched('isRetainer');
                    }}
                  />
                  <p>No</p>
                </YesNoRadioButtonContainer>
                {!!values.isRetainer && (
                  <div id="retainerAmount" style={{ paddingTop: '16px' }}>
                    <FormFieldTitle title="Monthly retainer amount" required />
                    <NumberFormat
                      id="retainerAmountInput"
                      name="retainerAmountInput"
                      allowNegative={false}
                      decimalSeparator={false}
                      thousandSeparator
                      onValueChange={value => {
                        handleChange('retainerAmount')(value.floatValue || null);
                        setFieldTouched('retainerAmount');
                      }}
                      value={values.retainerAmount}
                      onBlur={() => {
                        setFieldTouched('retainerAmount');
                      }}
                      style={touched.retainerAmount ? getStyles(errors, 'retainerAmount') : {}}
                      customInput={ExpectedDealSizeInput}
                    />
                    <ErrorField error={touched.retainerAmount && errors.retainerAmount} />
                  </div>
                )}
                <ErrorField error={touched.isRetainer && errors.isRetainer} />
              </FieldContainer>
            </ScrollToError>
            <ScrollToError field="expectedSize">
              <FieldContainer>
                <FormFieldTitle
                  title="Expected deal size (USD)"
                  required={values.marketplaceDealType === MarketplaceDealType.Collaborative}
                />
                <NumberFormat
                  id="expectedSize"
                  name="expectedSize"
                  allowNegative={false}
                  decimalSeparator={false}
                  thousandSeparator
                  onValueChange={value => {
                    handleChange('expectedSize')(value.floatValue);
                  }}
                  value={values.expectedSize}
                  onBlur={() => {
                    setFieldTouched('expectedSize');
                  }}
                  style={touched.expectedSize ? getStyles(errors, 'expectedSize') : {}}
                  customInput={ExpectedDealSizeInput}
                />
                <ErrorField error={errors.expectedSize} />
              </FieldContainer>
            </ScrollToError>
            <FieldContainer>
              <FormFieldTitle title="Description" />
              <TextArea
                className="deal-description"
                placeholder="Write a short description"
                maxLength={TEXT_AREA_MAX_VALUE}
                rows={3}
                onChange={handleChange('description')}
                value={values.description}
                onBlur={event => {
                  setFieldValue('description', event.target.value.trim());
                  setFieldTouched('description');
                }}
              />
              {/* TODO: SHOW COUNTER */}
            </FieldContainer>
            <FieldContainer>
              <FormFieldTitle title="Success fee" />
              <GrossFeeInputContainer>
                <ScrollToError field="minGrossFeeEarned">
                  <div className="min-input">
                    <NumberFormat
                      id="minFee"
                      name="minFee"
                      allowNegative={false}
                      thousandSeparator
                      value={values.minGrossFeeEarned}
                      onValueChange={value => {
                        handleChange('minGrossFeeEarned')(value.floatValue);
                      }}
                      onBlur={() => {
                        setFieldTouched('minGrossFeeEarned');
                      }}
                      style={
                        touched.minGrossFeeEarned
                          ? getStyles(errors, 'minGrossFeeEarned') ||
                            getStyles(errors, 'grossFeeEarned')
                          : {}
                      }
                      customInput={MinFeeInput}
                      decimalScale={2}
                      isAllowed={maxPercentValueAllowed}
                    />
                  </div>
                </ScrollToError>
                <ScrollToError field="maxGrossFeeEarned">
                  <div className="max-input">
                    <NumberFormat
                      id="maxFee"
                      name="maxFee"
                      allowNegative={false}
                      thousandSeparator
                      value={values.maxGrossFeeEarned}
                      onValueChange={value => {
                        handleChange('maxGrossFeeEarned')(value.floatValue);
                      }}
                      onBlur={() => {
                        setFieldTouched('maxGrossFeeEarned');
                      }}
                      style={
                        touched.maxGrossFeeEarned
                          ? getStyles(errors, 'maxGrossFeeEarned') ||
                            getStyles(errors, 'grossFeeEarned')
                          : {}
                      }
                      customInput={MaxFeeInput}
                      decimalScale={2}
                      isAllowed={maxPercentValueAllowed}
                    />
                  </div>
                </ScrollToError>
              </GrossFeeInputContainer>
              <ErrorField error={errors.minGrossFeeEarned || errors.grossFeeEarned} />
              <ErrorField error={errors.maxGrossFeeEarned} />
            </FieldContainer>
            <FieldContainer>
              <FormFieldTitle title="Engagement letter signed date" />
              <DatePickerConteiner>
                <div className="datepicker-select">
                  <DatePicker
                    inputPlaceholder="Pick a date"
                    calendarPopperPosition="auto"
                    formatInputText={formatDateInput}
                    value={values.engagementLetterSignedDate}
                    onChange={(day: any) => {
                      handleChange('engagementLetterSignedDate')(day);
                    }}
                    minimumDate={minimumDate}
                    maximumDate={maximumDate}
                  />
                </div>
                <div className="icon-container">
                  <CalendarIcon />
                </div>
              </DatePickerConteiner>
            </FieldContainer>
            <FieldContainer>
              <SelectField
                error={errors.tailPeriodDuration}
                placeholder="Select an option"
                name="tailPeriodDuration"
                options={tailPeriodOptions}
                title="Tail period expiration"
                value={getTailPeriodLabel(values.tailPeriodDuration)}
                errorStyle={getStyles(errors, 'tailPeriodDuration')}
                onChange={(e: any) => {
                  handleChange('tailPeriodDuration')(e.value);
                }}
                menuPlacement="top"
              />
            </FieldContainer>
          </BodyContainer>
        </SideDrawer>
      )}
    </Formik>
  );
};

export default withScrollToError(EditDealSideDrawer);
