import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { SuitecaseIcon } from '@dealsyte/poki';
import get from 'lodash/get';
import { UserContext } from 'app/users/context/UserContext';
import { ERoutePatterns } from 'app/core-tools/due-diligence/types/types';
import { ContextItemContainer } from '../../../../StyledGlobalSidebar';

import { Header, MainContainer } from './StyledDealContentSidebarHeader';

/**
 * Renders the deal content sidebar header as a trigger modal to open deal settings.
 */
function DealContentSidebarHeader() {
  const user = useContext(UserContext);

  const location = useLocation();

  /**
   * Gets the header text based on the current location.
   */
  function getHeaderText(): string {
    switch (location.pathname) {
      case ERoutePatterns.HOME:
        return get(user, 'activeDeal.name', 'Home');

      case ERoutePatterns.COMPLIANCE_TRACKER:
        return get(user, 'activeDeal.name', 'Compliance');

      default:
        return get(user, 'activeDeal.name', '...');
    }
  }

  const renderHeader = () => {
    return (
      <MainContainer style={{ cursor: 'default' }}>
        <ContextItemContainer
          style={{
            margin: '0 12px 0 0',
            backgroundColor: '#fff',
            color: '#959FAC',
            cursor: 'default',
          }}
        >
          <SuitecaseIcon />
        </ContextItemContainer>{' '}
        <Header>{getHeaderText()}</Header>
      </MainContainer>
    );
  };

  return renderHeader();
}

export default DealContentSidebarHeader;
