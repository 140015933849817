import React, { useContext, useEffect, useState } from 'react';
import { Modal } from '@dealsyte/poki';
import get from 'lodash/get';
import EventService from 'utils/socket/EventService';
import { UserContext } from 'app/users/context/UserContext';
import { DealMode, DealSide, Role } from 'app/core-tools/due-diligence/types/types';

import { Loader } from 'app/common/components';
import { DealType } from '../../../../../types/graphql-types';
import { getExternalTeamNotation } from '../../../../utils/helpers/helpers';
import ActiveSettingsSelector from './SettingsModalSelector';
import { MainContainer, SectionContainer, SidebarContainer, Title } from './StyledSettingsModal';

export enum EActiveSetting {
  DEAL_INFORMATION = 'Information',
  INTERNAL_GROUPS = 'Internal Groups',
  INVESTORS = 'Investors',
  BUYERS = 'Buyers',
  MARKETPLACE = 'Finalis Marketplace™',
  VDR = 'Data Room',
  ANALYTICS = 'Analytics',
}

export type IProps = {
  showVdrPermissions: boolean;
  show: boolean;
  setShow: () => void;
};

export const validatePermissions = (role: string, side: string): boolean =>
  role === Role.ADMIN && side === DealSide.SELL;

export const validateLightRole = (mode: string = DealMode.REGULAR, role: string): boolean => {
  if (mode !== DealMode.LIGHT) return true;
  return role === Role.ADMIN;
};

/**
 * Renders settings modal.
 * @param show Flag that indicates if the modal should or not be rendered.
 * @param setShow Toggler function for -show- flag.
 * @param showVdrPermissions Flag that indicates if vdr permissions should or not be available to configure.
 */
function SettingsModal({ show, setShow, showVdrPermissions }: IProps) {
  const { activeDeal, role, side, originRole, loadingDealData } = useContext(UserContext);
  const validation =
    validatePermissions(get(activeDeal, 'UserDeal.role'), get(activeDeal, 'UserDeal.side')) &&
    validateLightRole(get(activeDeal, 'mode'), originRole);

  const [activeSetting, setActiveSetting] = useState<EActiveSetting>(
    EActiveSetting.INTERNAL_GROUPS
  );

  function handleActiveSettingChange(newActiveSetting: EActiveSetting) {
    if (newActiveSetting !== activeSetting) setActiveSetting(newActiveSetting);
  }

  const EXTERNAL_GROUPS = getExternalTeamNotation(get(activeDeal, 'type') as DealType);

  const isSellsideAdmin = validatePermissions(role, side);

  useEffect(() => {
    setActiveSetting(EActiveSetting.INTERNAL_GROUPS);
  }, [activeDeal]);

  return (
    <Modal
      show={show}
      onHide={() => {
        EventService.triggerEvent('MODAL_HIDE', ['deal_settings']);
        setShow();
        setActiveSetting(EActiveSetting.INTERNAL_GROUPS);
      }}
      style={{ padding: 0, height: 670, minWidth: 1000 }}
    >
      <MainContainer>
        {loadingDealData ? (
          <Loader />
        ) : (
          <>
            <SidebarContainer>
              <Title>Invite members</Title>
              {isSellsideAdmin && (
                <SectionContainer
                  isActive={activeSetting === EActiveSetting.INTERNAL_GROUPS}
                  onClick={() => handleActiveSettingChange(EActiveSetting.INTERNAL_GROUPS)}
                >
                  {EActiveSetting.INTERNAL_GROUPS}
                </SectionContainer>
              )}
              {isSellsideAdmin && (
                <SectionContainer
                  isActive={activeSetting === EXTERNAL_GROUPS}
                  onClick={handleActiveSettingChange.bind(this, EXTERNAL_GROUPS as EActiveSetting)}
                >
                  {EActiveSetting.INVESTORS}
                </SectionContainer>
              )}
              {showVdrPermissions && (
                <SectionContainer
                  isActive={activeSetting === EActiveSetting.VDR}
                  onClick={() => handleActiveSettingChange(EActiveSetting.VDR)}
                >
                  {EActiveSetting.VDR}
                </SectionContainer>
              )}
            </SidebarContainer>

            <ActiveSettingsSelector
              activeSetting={activeSetting}
              deal={activeDeal}
              originRole={originRole}
            />
          </>
        )}
      </MainContainer>
    </Modal>
  );
}

export default SettingsModal;
