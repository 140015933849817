// vendors
import React from 'react';
import Select, { ValueType } from 'react-select';
import get from 'lodash/get';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-modern-calendar-datepicker';

// components
import { Button, EButtonType, FormGroup, InfoIcon, Tooltip } from '@dealsyte/poki';
import FormYesNoQuestion from 'app/compliance/components/FormYesNoQuestion/FormYesNoQuestion';
import { TextArea } from 'app/common/components';

// constants
import {
  DEAL_FORM_LABELS,
  DEAL_STATUS_OPTIONS,
  DEAL_TARGET_INVESTOR_OPTIONS,
  DEAL_TYPES_OPTIONS,
} from 'app/utils/helpers/deal';
import {
  MAX_DEAL_EARNINGS,
  MAX_DEAL_SIZE,
  MIN_DEAL_SIZE,
} from 'app/utils/constants/app/appConstants';
import { minimumDate } from 'app/utils/constants/app/appConstants';

// helpers
import { formControlChangeHandlers } from 'app/compliance/components/FormControlChangeHandlers/FormControlChangeHandlers';
import 'app/compliance/components/Steps/Shared/datepicker.css';

// types
import { EDealStatus } from 'app/utils/types/types';
import { UpdateDealForm } from './TypedDealInformationSettings';

// styles
import * as S from './StyledDealInformationSettings';

// Handler
import { useDealInformationSettingsHandler } from './DealInformationSettingsHandler';
import {
  DEAL_INDUSTRY_TYPES_OPTIONS,
  getIndustryTypeSubOptions,
} from 'app/utils/helpers/dealIndustryTypes';
import { countries } from 'app/utils/helpers/countries';

const sanitizeInputValues = (val: any) => {
  if (!val) return '';
  if (typeof val !== 'string' && typeof val !== 'number') return '';
  return val;
};

/**
 * Deal Information Settings tab.
 */
const DealInformationSettings = (): JSX.Element => {
  const {
    form,
    isDealOwner,
    isStatusDDClosing,
    hasActiveFirm,
    hasEnoughMembersToShare,
    currentFirm,
    loadingUpdateDeal,
    updateDeal,
  } = useDealInformationSettingsHandler();
  const { values, errors, isValid, dirty, setFieldValue, touched } = form;
  const {
    handleSelectChange,
    handleFieldChange,
    handleYesNoChange,
    handleDateChange,
    handleNumberInputChange,
  } = formControlChangeHandlers<UpdateDealForm>(setFieldValue);
  return (
    <div style={{ flex: 1 }}>
      <S.MainContainer>
        <S.ContentContainer>
          <S.Subtitle>{DEAL_FORM_LABELS.DEAL_SETTINGS_TAB_TITLE} </S.Subtitle>

          <FormGroup
            id="projectName"
            label={DEAL_FORM_LABELS.PROJECT_NAME}
            input={
              <S.Input
                id="projectName"
                name="projectName"
                data-testid="projectName"
                onChange={handleFieldChange('projectName')}
                value={sanitizeInputValues(values.projectName)}
              />
            }
          />

          <FormGroup
            id="dealName"
            label={DEAL_FORM_LABELS.DEAL_NAME}
            errorMessage={get(errors, 'name')}
            input={
              <S.Input
                id="dealName"
                name="dealName"
                data-testid="dealName"
                maxLength={120}
                onChange={handleFieldChange('name')}
                value={sanitizeInputValues(values.name)}
              />
            }
          />

          <FormGroup
            id="dealType"
            label={DEAL_FORM_LABELS.DEAL_TYPE}
            input={
              <Select
                options={DEAL_TYPES_OPTIONS}
                inputId="dealType"
                styles={S.selectDealCustomStyles}
                onChange={handleSelectChange('type')}
                value={values.type}
                name="colors"
                className="dealType"
                classNamePrefix="dealType"
                isSearchable={false}
              />
            }
          />
          <FormGroup
            id="dealStatus"
            label={DEAL_FORM_LABELS.DEAL_STATUS}
            input={
              <Select
                options={DEAL_STATUS_OPTIONS}
                inputId="dealStatus"
                styles={S.selectDealCustomStyles}
                onChange={handleSelectChange('dealStatus')}
                value={values.dealStatus as ValueType<{ label: string; value: EDealStatus }>}
                name="colors"
                className="dealStatus"
                classNamePrefix="dealStatus"
                isSearchable={false}
              />
            }
          />

          {isDealOwner && hasActiveFirm && hasEnoughMembersToShare && (
            <FormGroup
              id="private"
              input={
                <FormYesNoQuestion
                  id="private_question"
                  // touched={!!touched.private}
                  checked={values.private}
                  label={`Do you want to share this deal with the firm? ${
                    currentFirm ? `(${currentFirm.name})` : ''
                  }`}
                  onChange={handleYesNoChange('private')}
                />
              }
            />
          )}

          {isStatusDDClosing && (
            <FormGroup
              id="loiProvided"
              input={
                <FormYesNoQuestion
                  // touched={!!touched.loiProvided}
                  checked={values.loiProvided}
                  id="relationGroup_question1"
                  label="Whether a copy of LOI/Term sheet of this deal has been provided to compliance at compliance@finalis.com?"
                  onChange={handleYesNoChange('loiProvided')}
                />
              }
            />
          )}

          <FormGroup
            id="expectedSize"
            style={{ marginTop: 16 }}
            errorMessage={
              get(errors, 'expectedSize') &&
              'Expected size should be a whole value between $1-$2,147,483,647.'
            }
            label={DEAL_FORM_LABELS.DEAL_EXPECTED_DEAL_SIZE}
            input={
              <NumberFormat
                min={MIN_DEAL_SIZE}
                max={MAX_DEAL_SIZE}
                id="expectedSize"
                name="expectedSize"
                data-testid="expectedSize"
                onValueChange={handleNumberInputChange('expectedSize')}
                value={sanitizeInputValues(values.expectedSize)}
                style={{ fontSize: 12 }}
                displayType="input"
                thousandSeparator
                decimalSeparator={false}
                allowNegative={false}
                prefix="$"
                customInput={S.Input}
              />
            }
          />

          <FormGroup
            id="expectedCommissions"
            errorMessage={
              get(errors, 'expectedCommissions') &&
              'Expected commission earnings should be zero or a positive whole value lower than deal size.'
            }
            label={DEAL_FORM_LABELS.DEAL_EXPECTED_COMMISSION}
            input={
              <NumberFormat
                min="0"
                max={MAX_DEAL_EARNINGS}
                id="expectedCommissions"
                name="expectedCommissions"
                data-testid="expectedCommissions"
                onValueChange={handleNumberInputChange('expectedCommissions')}
                value={sanitizeInputValues(values.expectedCommissions)}
                style={{ fontSize: 12 }}
                displayType="input"
                thousandSeparator
                decimalSeparator={false}
                allowNegative={false}
                prefix="$"
                customInput={S.Input}
              />
            }
          />

          {isStatusDDClosing && (
            <FormGroup
              label="Estimated Closing date"
              id="estimatedClosingDate"
              errorMessage={
                get(errors, 'closingDate') && 'Please provide an estimated closing date.'
              }
              input={
                <DatePicker
                  value={values.closingDate}
                  calendarPopperPosition="bottom"
                  onChange={handleDateChange('closingDate')}
                  inputPlaceholder="Select a date"
                  inputClassName="my-custom-input"
                  minimumDate={minimumDate}
                />
              }
            />
          )}
        </S.ContentContainer>
        <S.DealInformationContainer>
          <S.ContentContainer>
            <S.Subtitle>{DEAL_FORM_LABELS.CLIENT_SECTION_TITLE}</S.Subtitle>

            <S.Text>{DEAL_FORM_LABELS.CLIENT_SECTION_DESCRIPTION}</S.Text>

            <FormGroup
              id="companyName"
              label={DEAL_FORM_LABELS.CLIENT_NAME}
              input={
                <S.Input
                  id="companyName"
                  name="companyName"
                  data-testid="companyName"
                  onChange={handleFieldChange('companyName')}
                  value={sanitizeInputValues(values.companyName)}
                />
              }
            />

            <FormGroup
              id="companyAgent"
              label={DEAL_FORM_LABELS.CLIENT_RESPONSABLE_PERSON}
              input={
                <S.Input
                  id="companyAgent"
                  name="companyAgent"
                  data-testid="companyAgent"
                  onChange={handleFieldChange('companyAgent')}
                  value={sanitizeInputValues(values.companyAgent)}
                />
              }
            />
            <FormGroup
              id="companyAddress"
              label={DEAL_FORM_LABELS.CLIENT_ADDRESS}
              input={
                <S.Input
                  id="companyAddress"
                  name="companyAddress"
                  data-testid="companyAddress"
                  onChange={handleFieldChange('companyAddress')}
                  value={sanitizeInputValues(values.companyAddress)}
                />
              }
            />
            <FormGroup
              id="country"
              label={DEAL_FORM_LABELS.COUNTRY}
              errorMessage={touched.country && errors.country}
              input={
                <Select
                  options={countries}
                  inputId="country"
                  name="country"
                  value={values.country}
                  onChange={handleSelectChange('country')}
                  maxMenuHeight={200}
                  placeholder="Enter country"
                  styles={S.selectDealCustomStyles}
                  isSearchable
                />
              }
            />
            <FormGroup
              id="state"
              errorMessage={touched.state && errors.state}
              label={DEAL_FORM_LABELS.STATE}
              input={
                <S.Input
                  id="state"
                  name="state"
                  value={sanitizeInputValues(values.state)}
                  onChange={handleFieldChange('state')}
                />
              }
            />
            <FormGroup
              id="zipCode"
              errorMessage={touched.zipCode && errors.zipCode}
              label={DEAL_FORM_LABELS.ZIP_CODE}
              input={
                <S.Input
                  id="zipCode"
                  name="zipCode"
                  value={sanitizeInputValues(values.zipCode)}
                  onChange={handleFieldChange('zipCode')}
                />
              }
            />

            <FormGroup
              id="industryType"
              label={DEAL_FORM_LABELS.INDUSTRY_TYPE}
              input={
                <Select
                  options={DEAL_INDUSTRY_TYPES_OPTIONS}
                  styles={S.selectDealCustomStyles}
                  inputId="industryType"
                  name="industryType"
                  value={values.industryType}
                  onChange={(evt: any) => {
                    handleSelectChange('industryType')(evt);
                    handleSelectChange('industryTypeSubOptions')(null);
                  }}
                  maxMenuHeight={200}
                  isSearchable={false}
                />
              }
            />
            {get(values, 'industryType.value', '') && (
              <FormGroup
                id="industryTypeSubOptions"
                label={DEAL_FORM_LABELS.INDUSTRY_SUB_TYPE}
                input={
                  <Select
                    options={getIndustryTypeSubOptions(get(values, 'industryType.value', null))}
                    styles={S.selectDealCustomStyles}
                    inputId="industryTypeSubOptions"
                    name="industryTypeSubOptions"
                    value={values.industryTypeSubOptions}
                    onChange={handleSelectChange('industryTypeSubOptions')}
                    maxMenuHeight={200}
                    isSearchable={false}
                  />
                }
              />
            )}

            <FormGroup
              id="publiclyTradedCompany"
              input={
                <FormYesNoQuestion
                  checked={values.publiclyTradedCompany}
                  // touched={!!touched.publiclyTradedCompany}
                  id="publiclyTradedCompany"
                  label={DEAL_FORM_LABELS.PUBLICITY_TRADED_COMPANY}
                  onChange={handleYesNoChange('publiclyTradedCompany')}
                />
              }
            />

            {values.publiclyTradedCompany && (
              <FormGroup
                id="ticker"
                label={DEAL_FORM_LABELS.TICKER_EXCHANGE}
                input={
                  <S.Input
                    id="ticker"
                    name="ticker"
                    value={sanitizeInputValues(values.ticker)}
                    onChange={handleFieldChange('ticker')}
                  />
                }
              />
            )}

            <FormGroup
              id="initialDueDiligence"
              label={DEAL_FORM_LABELS.INITIAL_DUE_DILIGENCE}
              input={
                <TextArea
                  rows={3}
                  name="initialDueDiligence"
                  onChange={handleFieldChange('initialDueDiligence')}
                  value={sanitizeInputValues(values.initialDueDiligence)}
                />
              }
            />

            <FormGroup
              id="potentialConflicts"
              label={DEAL_FORM_LABELS.POTENTIAL_CONFLICTS}
              input={
                <TextArea
                  rows={3}
                  name="potentialConflicts"
                  onChange={handleFieldChange('potentialConflicts')}
                  value={sanitizeInputValues(values.potentialConflicts)}
                />
              }
            />

            <FormGroup
              id="targetInvestors"
              label={
                <>
                  {DEAL_FORM_LABELS.TARGET_INVESTORS}
                  <Tooltip
                    message={DEAL_FORM_LABELS.TARGET_INVESTORS_TOOLTIP}
                    style={{ padding: 20, lineHeight: 1.5 }}
                  >
                    <InfoIcon style={{ paddingLeft: 5 }} />
                  </Tooltip>
                </>
              }
              input={
                <Select
                  options={DEAL_TARGET_INVESTOR_OPTIONS}
                  styles={S.selectDealCustomStyles}
                  inputId="targetInvestors"
                  name="targetInvestors"
                  value={values.targetInvestors}
                  onChange={handleSelectChange('targetInvestors')}
                  maxMenuHeight={200}
                  isSearchable={false}
                />
              }
            />

            <FormGroup
              id="foreignSolicitation"
              input={
                <FormYesNoQuestion
                  checked={values.foreignSolicitation}
                  // touched={!!touched.foreignSolicitation}
                  id="foreignSolicitation"
                  label={DEAL_FORM_LABELS.FOREIGN_SOLICITATION}
                  onChange={handleYesNoChange('foreignSolicitation')}
                />
              }
            />
          </S.ContentContainer>
        </S.DealInformationContainer>
      </S.MainContainer>
      <S.SubmitButtonsBotomFixedContainer>
        <S.ErrorLabelContainer>
          {!isValid && (
            <S.ErrorMessageText style={{ marginBottom: 0 }}>
              {DEAL_FORM_LABELS.DEAL_UPDATE_VALIDATION_ERROR_MESSAGE}
            </S.ErrorMessageText>
          )}
        </S.ErrorLabelContainer>
        <S.SubmitButtonContainer>
          <Button
            disabled={loadingUpdateDeal || !isValid || !dirty}
            buttonType={EButtonType.primary}
            onClick={updateDeal}
          >
            {DEAL_FORM_LABELS.UPDATE_DEAL_ACTION}
          </Button>
        </S.SubmitButtonContainer>
      </S.SubmitButtonsBotomFixedContainer>
    </div>
  );
};

export default DealInformationSettings;
