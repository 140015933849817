// Libraries
import React, { useState, useEffect, useContext, useMemo } from 'react';

// Context
import { FilterContext } from 'app/marketplace/context/FilterContext';
import { UserContext } from 'app/users/context/UserContext';

// Components
import MulticheckFilter from '../../filters/multicheckFilter/MulticheckFilter';
import Switch from '../../../../common/components/Switch/Switch';
import RangeFilter from 'app/marketplace/components/filters/rangeFilter/RangeFilter';
import CustomCheckboxFilter from '../../../components/filters/customCheckboxFilter/CustomCheckboxFilter';

// Mapper
import mapAdvancedFilters from './AdvancedFilterMapper';

// Styled components
import { SeparatorLine, FormContent } from './StyledAdvancedFilterSideDrawer';

// Types
import { DealFilterEnum } from 'app/marketplace/types';
import { IFilterValues } from 'app/marketplace/context/TypedFilterContext';
import { ERangeType } from '../../filters/rangeFilter/RangeFilterType';

// Hooks
import useStateModal from 'app/utils/hooks/useStateModal';
import { Button, SideDrawer } from 'app/common/components';
import { FINALIS_CONNECT, BANKER } from 'app/utils/constants/app/appConstants';
import { mapOriginatedByFilter } from 'app/marketplace/sections/marketplaceBrowser/MarketplaceBrowserFilterMapper';
import useGoogleAnalytics from 'app/utils/hooks/useGoogleAnalytics';

function AdvancedFilterSideDrawer(): JSX.Element {
  const {
    filterState,
    marketplaceDealFilter,
    handleChangeFilter: onChangeFilter,
    filtersApplied,
  } = useContext(FilterContext);
  const { marketplaceAccessEnabled } = useContext(UserContext);

  const { setFieldValue, filterValues, resetForm } = filterState;

  const { modalState, hide } = useStateModal('advancedFilterSideDrawer');
  const { show } = modalState;

  // Checked filters
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const { trackMultipleEvents } = useGoogleAnalytics();

  const handleRangeInputChange = (field: keyof IFilterValues) => (value: {
    from: number;
    to: number;
  }) => {
    setFieldValue(field, value);
  };

  const handleSwitchChange = (field: keyof IFilterValues) => (evt: any) => {
    setFieldValue(field, evt.target.checked);
  };

  const handleCheckChange = (field: keyof IFilterValues) => (value: any[]) => {
    setFieldValue(field, value);
  };

  useEffect(() => {
    if (filtersApplied.length === 0) {
      resetForm();
    }
  }, [filtersApplied]);

  const handleApplyFilters = () => {
    onChangeFilter([
      ...mapAdvancedFilters(filterValues),
      ...mapOriginatedByFilter(
        filterValues.originatedBy,
        DealFilterEnum.ORIGINATED_BY,
        trackMultipleEvents
      ),
    ]);
    hide();
  };

  const typeFilters = marketplaceDealFilter
    ? marketplaceDealFilter.find((item: any) => item.filterName === DealFilterEnum.TYPE)
    : [];

  const getOriginatedByFilters = () => {
    if (marketplaceDealFilter.length === 0) {
      return { filterName: 'FIRM_ID', values: [] };
    }
    const filter = marketplaceDealFilter
      ? marketplaceDealFilter.find((item: any) => item.filterName === DealFilterEnum.ORIGINATED_BY)
      : [];

    const result = filter
      ? filter.values.reduce(
          (acc, item) => {
            item.filterElement === process.env.REACT_APP_FIRM_ID_PRODUCTION_WHITELIST
              ? (acc.finalis = item.count || 0)
              : (acc.other += item.count || 0);
            return acc;
          },
          { finalis: 0, other: 0 }
        )
      : { finalis: 0, other: 0 };

    return {
      filterName: 'FIRM_ID',
      values: [
        {
          filterElement: FINALIS_CONNECT,
          checked: false,
          filterParent: null,
          count: result.finalis,
        },
        {
          filterElement: BANKER,
          checked: false,
          filterParent: null,
          count: result.other,
        },
      ],
    };
  };

  const originatedByFilters = useMemo(getOriginatedByFilters, [marketplaceDealFilter]);

  const primaryIndustryFilters = marketplaceDealFilter
    ? marketplaceDealFilter.find((item: any) => item.filterName === DealFilterEnum.PRIMARY_INDUSTRY)
    : [];

  const secondaryIndustryFilters = marketplaceDealFilter
    ? marketplaceDealFilter.find(
        (item: any) => item.filterName === DealFilterEnum.SECONDARY_INDUSTRIES
      )
    : [];

  /* commented as it isn't used
  const locationFilters = {
    filterName: DealFilterEnum.LOCATION,
    values:
      stateFilters && stateFilters.values
        ? ([
            ...new Set<string>(stateFilters.values.map((item: any) => item.filterParent)),
          ] as string[]).map((item: string) => ({
            filterElement: item,
            filterParent: null,
            count: stateFilters.values.reduce((prev: number, next: any) => {
              if (next.filterParent === item) return next.count + prev;
              return prev;
            }, 0),
          }))
        : [],
  };
  */
  return (
    <SideDrawer
      show={show}
      onClose={hide}
      title="Advanced filters"
      footerContent={
        <Button onClick={handleApplyFilters} disabled={isButtonDisabled} style={{ width: '100%' }}>
          Show deals
        </Button>
      }
    >
      <FormContent>
        <div className="filter-box">
          <div className="filter-label">Deal type</div>
          <CustomCheckboxFilter
            filterName={DealFilterEnum.TYPE}
            filters={typeFilters}
            onChangeFilter={handleCheckChange('dealType')}
            filtersSelected={filterValues.dealType}
            marketplaceAccessEnabled={marketplaceAccessEnabled}
          />
        </div>
        <SeparatorLine />
        <div className="filter-box">
          <div className="filter-label">Originated by</div>
          <CustomCheckboxFilter
            filterName={DealFilterEnum.ORIGINATED_BY}
            filters={originatedByFilters}
            onChangeFilter={handleCheckChange('originatedBy')}
            filtersSelected={filterValues.originatedBy}
            marketplaceAccessEnabled={marketplaceAccessEnabled}
          />
        </div>
        <SeparatorLine />
        <div className="filter-box">
          <div className="filter-label">Sector</div>
          <MulticheckFilter
            valuesSelected={filterValues.industries}
            primaryOptions={primaryIndustryFilters}
            secondaryOptions={secondaryIndustryFilters}
            onChangeFilter={handleCheckChange('industries')}
            marketplaceAccessEnabled={marketplaceAccessEnabled}
          />
        </div>
        <SeparatorLine />
        {/* <div className="filter-box"> !! This is commented because the location filter is not working properly
          <div className="filter-label">Location</div>
          <MulticheckFilter
            valuesSelected={filterValues.location}
            primaryOptions={locationFilters}
            secondaryOptions={stateFilters}
            onChangeFilter={handleCheckChange('location')}
          />
        </div>
        <SeparatorLine /> */}
        <div className="filter-box">
          <div className="filter-label">Deal size</div>
          <RangeFilter
            value={filterValues.dealSize}
            handleChange={handleRangeInputChange('dealSize')}
            options={{
              setDisable: setIsButtonDisabled,
              allowVoidEntries: true,
            }}
            disable={!marketplaceAccessEnabled}
          />
        </div>
        <SeparatorLine />
        <div className="filter-box">
          <div className="filter-label">Success fee</div>
          <RangeFilter
            value={filterValues.successFee}
            handleChange={handleRangeInputChange('successFee')}
            options={{
              setDisable: setIsButtonDisabled,
              allowVoidEntries: true,
              type: ERangeType.PERCENTAGE,
            }}
            disable={!marketplaceAccessEnabled}
          />
        </div>
        <SeparatorLine />
        <div className="filter-box">
          <div className="filter-label" style={{ paddingBottom: '20px' }}>
            Retainer
          </div>
          <div className="filter-retainer">
            <p>Will the deal have a monthly retainer?</p>
            <Switch
              id="isRetainerCheckbox"
              onChange={handleSwitchChange('isRetainerActive')}
              checked={filterValues.isRetainerActive}
              className="filter-switch"
              disabled={!marketplaceAccessEnabled}
            />
          </div>
          {filterValues.isRetainerActive && (
            <RangeFilter
              value={filterValues.isRetainer}
              handleChange={handleRangeInputChange('isRetainer')}
              options={{
                setDisable: setIsButtonDisabled,
                allowVoidEntries: true,
              }}
            />
          )}
        </div>
      </FormContent>
    </SideDrawer>
  );
}

export default AdvancedFilterSideDrawer;
