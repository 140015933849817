// Libraries
import React, { useState, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import _get from 'lodash/get';
import DatePicker, { DayValue, utils as calendarUtils } from 'react-modern-calendar-datepicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import NumberFormat from 'react-number-format';

// Design System
import { SideDrawer, Input } from 'app/common/components';

// Context
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { FilterContext } from 'app/marketplace/context/FilterContext';

// @poki
import { FormGroup, Textarea, Checkbox } from '@dealsyte/poki';

// constants
import { ELabels } from 'app/navigation/GlobalSidebar/components/SettingsModal/components/MarketplaceSettings/TypedMarketplaceSettings';
import { MIN_DEAL_SIZE, MAX_DEAL_SIZE, maximumDate, minimumDate  } from 'app/utils/constants/app/appConstants';
import { DEAL_FORM_LABELS, DEAL_STATUS_OPTIONS, DEAL_TYPES_OPTIONS } from 'app/utils/helpers/deal';
import {
  DEAL_INDUSTRY_TYPES_OPTIONS,
  getIndustryTypeSubOptions,
} from 'app/utils/helpers/dealIndustryTypes';
import { TEXT_AREA_MAX_VALUE } from '../../../constants/index';

// Mutations
import { UPSERT_NON_COMPLIANCE_MARKETPLACE_DEAL } from '../../../../../api/graphql/marketplace/mutations';

// helpers
import {
  getTailPeriodLabel,
  tailPeriodOptions,
} from '../../../../navigation/GlobalSidebar/components/SettingsModal/components/MarketplaceSettings/HelpersMarketplaceSettings';
import { formatDateInput, maxPercentValueAllowed } from '../../../../utils/helpers/helpers';
import { buildInitialValues, buildPayload, getStyles } from './CreateMMDealSideDrawerHelper';

// Styles
import {
  SideDrawerContent,
  ButtonsContainer,
  SelectCustomStyles,
  SuccessContainer,
} from './StyledCreateMMDealSideDrawer';

// Icons
import DollarIcon from '../../../assets/dollarIcon';

// Validation Schema
import { validationSchema } from './CreateMMDealSideDrawerFormValidation';

// Hooks
import useStateModal from '../../../../utils/hooks/useStateModal';

function CreateMMDealSideDrawer(): JSX.Element {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const { modalState, hide: hideSideDrawer } = useStateModal('createMMDealSideDrawer');
  const { props: modalProps, show } = modalState;
  const { dealSelected } = modalProps;

  const [createNonComplianceMMDeal] = useMutation(UPSERT_NON_COMPLIANCE_MARKETPLACE_DEAL, {
    onCompleted() {
      const toastMessage = dealSelected ? 'Deal Update Success' : 'Deal Creation Success';
      showSuccessToast({ title: toastMessage });
    },
    onError(error: any) {
      const toastMessage = dealSelected ? 'Deal Update Error' : 'Deal Creation Error';
      showErrorToast({ title: toastMessage, description: '' }, error);
    },
  });
  const [isRetainer, setIsRetainer] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>(buildInitialValues());
  const dealStatusToSelect = DEAL_STATUS_OPTIONS;

  const { handleClearFilters: refetch } = useContext(FilterContext);

  const ExpectedDealSizeInput = React.useMemo(
    () => (props: any) => <Input leftIcon={<DollarIcon />} {...props} />,
    []
  );

  const MinFeeInput = React.useMemo(
    () => (props: any) => <Input prefix="Min" suffix="%" {...props} />,
    []
  );

  const MaxFeeInput = React.useMemo(
    () => (props: any) => <Input prefix="Max" suffix="%" {...props} />,
    []
  );

  useEffect(() => {
    if (dealSelected) {
      const firstValues = buildInitialValues(dealSelected);
      setInitialValues(firstValues);
      setIsRetainer(firstValues.isRetainer);
    } else {
      setInitialValues(buildInitialValues());
    }
  }, [dealSelected]);

  const handleClose = (resetForm: () => void) => {
    resetForm();
    setIsRetainer(false);
    hideSideDrawer();
  };

  const handleCreateNonComplianceMMDeal = async (values: any, resetForm: () => void) => {
    let nonComplianceMarketplaceDealPayload = buildPayload(values);
    if (dealSelected) {
      nonComplianceMarketplaceDealPayload = {
        ...nonComplianceMarketplaceDealPayload,
        id: _get(dealSelected, 'id'),
      };
    }
    createNonComplianceMMDeal({
      variables: {
        nonComplianceMarketplaceDeal: nonComplianceMarketplaceDealPayload,
      },
    }).then(() => {
      refetch();
      handleClose(resetForm);
    });
  };

  const handleSelectChange = (field: string, setFieldValue: any, subField?: string) => (
    value: any
  ) => {
    setFieldValue(field, value);
    if (subField) {
      setFieldValue(subField, null);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { resetForm }) => {
        handleCreateNonComplianceMMDeal(values, resetForm);
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        setFieldValue,
        values,
        errors,
        handleSubmit,
        resetForm,
        touched,
        setFieldTouched,
        handleChange,
        handleBlur,
      }) => (
        <SideDrawer
          show={show}
          onClose={() => handleClose(resetForm)}
          title="Create MM Deal"
          footerContent={
            <ButtonsContainer>
              <button
                type="submit"
                className="filter-button"
                onClick={event => {
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                <p>{dealSelected ? 'Update' : 'Create'} MM Deal</p>
              </button>
            </ButtonsContainer>
          }
        >
          <SideDrawerContent>
            <FormGroup
              label={DEAL_FORM_LABELS.DEAL_TYPE}
              id="transactionType"
              required
              errorMessage={touched.transactionType && errors.transactionType}
              input={
                <Select
                  placeholder="Select a Transaction Type"
                  options={DEAL_TYPES_OPTIONS}
                  styles={SelectCustomStyles(getStyles(errors, 'transactionType'))}
                  inputId="transactionType"
                  name="transactionType"
                  onChange={(
                    inSelectTransactionType: ValueType<{ value: String; label: String }>
                  ) => {
                    handleChange('transactionType')(inSelectTransactionType);
                  }}
                  value={values.transactionType}
                  maxMenuHeight={200}
                  isSearchable={false}
                />
              }
            />
            <FormGroup
              id="dealStatus"
              label="Deal Status"
              required
              errorMessage={touched.dealStatus && errors.dealStatus}
              input={
                <Select
                  options={dealStatusToSelect}
                  inputId="dealStatus"
                  placeholder="Select a Deal Status"
                  styles={SelectCustomStyles(getStyles(errors, 'dealStatus'))}
                  onChange={(inSelectDealStatus: ValueType<{ value: String; label: String }>) => {
                    handleChange('dealStatus')(inSelectDealStatus);
                  }}
                  value={values.dealStatus}
                  name="colors"
                  className="dealStatus"
                  classNamePrefix="dealStatus"
                  isSearchable={false}
                />
              }
            />
            <FormGroup
              id="expectedSize"
              style={{ marginTop: 16 }}
              errorMessage={touched.expectedSize && errors.expectedSize}
              label="Expected Deal Size (USD)"
              required
              input={
                <NumberFormat
                  min={MIN_DEAL_SIZE}
                  max={MAX_DEAL_SIZE}
                  id="expectedSize"
                  name="expectedSize"
                  data-testid="expectedSize"
                  onValueChange={value => {
                    handleChange('expectedSize')(value.floatValue);
                  }}
                  value={values.expectedSize}
                  style={{ fontSize: 12 }}
                  displayType="input"
                  thousandSeparator
                  decimalSeparator={false}
                  allowNegative={false}
                  prefix="$"
                  customInput={Input}
                />
              }
            />
            <FormGroup
              id="country"
              label={DEAL_FORM_LABELS.COUNTRY}
              errorMessage={touched.country && errors.country}
              required
              input={
                <Input
                  id="country"
                  name="country"
                  value={values.country}
                  onChange={handleChange}
                  onBlur={event => {
                    setFieldValue('country', event.target.value.trim());
                    setFieldTouched('country');
                  }}
                />
              }
            />
            <FormGroup
              id="state"
              label={DEAL_FORM_LABELS.STATE}
              errorMessage={touched.state && errors.state}
              required
              input={
                <Input
                  id="state"
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                  onBlur={event => {
                    setFieldValue('state', event.target.value.trim());
                    setFieldTouched('state');
                  }}
                />
              }
            />
            <FormGroup
              id="industryType"
              label={DEAL_FORM_LABELS.INDUSTRY_TYPE}
              errorMessage={touched.industryType && errors.industryType}
              required
              input={
                <Select
                  options={DEAL_INDUSTRY_TYPES_OPTIONS}
                  inputId="industryType"
                  name="industryType"
                  onChange={handleSelectChange(
                    'industryType',
                    setFieldValue,
                    'industryTypeSubOptions'
                  )}
                  maxMenuHeight={200}
                  styles={SelectCustomStyles(getStyles(errors, 'industryType'))}
                  isSearchable={false}
                  value={values.industryType}
                />
              }
            />
            {values.industryType && (
              <FormGroup
                id="industryTypeSubOptions"
                label={DEAL_FORM_LABELS.INDUSTRY_SUB_TYPE}
                input={
                  <Select
                    options={getIndustryTypeSubOptions(_get(values, 'industryType.value', null))}
                    inputId="industryTypeSubOptions"
                    name="industryTypeSubOptions"
                    value={values.industryTypeSubOptions}
                    onChange={handleSelectChange('industryTypeSubOptions', setFieldValue)}
                    maxMenuHeight={200}
                    styles={SelectCustomStyles(getStyles(errors, 'industryTypeSubOptions'))}
                    isSearchable={false}
                  />
                }
              />
            )}

            <FormGroup
              id="description"
              label={ELabels.MARKETPLACE_DESCRIPTION_LABEL}
              required
              errorMessage={errors.description && touched.description ? errors.description : ''}
              input={
                <Textarea
                  className="textArea"
                  placeholder="Provide your notes, details or description to clarify the deal"
                  id="description"
                  name="description"
                  style={{ height: 80, ...getStyles(errors, 'description') }}
                  data-testid="description"
                  onChange={handleChange}
                  value={values.description}
                  onBlur={event => {
                    setFieldValue('description', event.target.value.trim());
                    setFieldTouched('description');
                  }}
                  maxLength={TEXT_AREA_MAX_VALUE}
                />
              }
            />

            <FormGroup
              id="isRetainer"
              label={ELabels.MARKETPLACE_RETAINER_LABEL}
              style={{ paddingTop: 16 }}
              input={
                <Checkbox
                  type="default"
                  onChange={() => {
                    setIsRetainer(!isRetainer);
                    setFieldValue('isRetainer', !isRetainer);
                  }}
                  checked={isRetainer}
                  style={{ marginTop: 3, width: 15, borderColor: '#6b79b3' }}
                />
              }
            />
            {isRetainer && (
              <FormGroup
                errorMessage={
                  errors.retainerAmount && touched.retainerAmount ? errors.retainerAmount : ''
                }
                id="retainerAmount"
                label={ELabels.MARKETPLACE_RETAINER_AMOUNT_LABEL}
                style={{ paddingTop: 16 }}
                input={
                  <NumberFormat
                    id="retainerAmount"
                    name="retainerAmount"
                    allowNegative={false}
                    decimalSeparator={false}
                    thousandSeparator
                    onValueChange={(e: any) => {
                      setFieldValue('retainerAmount', e.value);
                      setFieldTouched('retainerAmount');
                    }}
                    value={values.retainerAmount}
                    onBlur={handleBlur}
                    customInput={ExpectedDealSizeInput}
                  />
                }
              />
            )}
            <FormGroup
              id="tailPeriodDuration"
              errorMessage={
                errors.tailPeriodDuration && touched.tailPeriodDuration
                  ? errors.tailPeriodDuration
                  : ''
              }
              label={ELabels.MARKETPLACE_TAIL_PERIOD_EXPIRATION_LABEL}
              style={{ paddingTop: 16 }}
              input={
                <div className="datePickerContainerOne datePickerContainer">
                  <Select
                    placeholder="Select a Tail Period"
                    options={tailPeriodOptions}
                    styles={SelectCustomStyles(getStyles(errors, 'tailPeriodDuration'))}
                    onChange={(e: any) => {
                      setFieldTouched('tailPeriodDuration');
                      setFieldValue('tailPeriodDuration', e.value);
                    }}
                    value={getTailPeriodLabel(values.tailPeriodDuration)}
                    name="colors"
                    className="react-select"
                    classNamePrefix="react-select"
                    isSearchable={false}
                  />
                </div>
              }
            />

            <FormGroup
              id="engagementLetterSignedDate"
              errorMessage={
                errors.engagementLetterSignedDate && touched.engagementLetterSignedDate
                  ? errors.engagementLetterSignedDate
                  : ''
              }
              label={ELabels.MARKETPLACE_EL_SIGNED_DATE_LABEL}
              style={{ paddingTop: 16 }}
              input={
                <div className="datePickerContainer datePickerContainerThree">
                  <DatePicker
                    colorPrimary="#47455F"
                    value={values.engagementLetterSignedDate}
                    minimumDate={minimumDate}
                    maximumDate={maximumDate}
                    calendarPopperPosition="bottom"
                    onChange={(day: DayValue) => {
                      setFieldTouched('engagementLetterSignedDate');
                      setFieldValue('engagementLetterSignedDate', day);
                    }}
                    formatInputText={formatDateInput}
                    inputPlaceholder="Enter your date"
                    inputClassName="datePicker"
                    renderFooter={() => (
                      <div className="datePickerFooter">
                        <button
                          type="button"
                          onClick={() => {
                            setFieldValue('engagementLetterSignedDate', null);
                          }}
                          className="input"
                        >
                          Clear Date
                        </button>
                      </div>
                    )}
                  />
                </div>
              }
            />

            <FormGroup
              id="minSuccessFee"
              style={{ paddingTop: 16 }}
              errorMessage={
                (touched.minSuccessFee && errors.minSuccessFee) ||
                (touched.maxSuccessFee && errors.maxSuccessFee)
              }
              required
              label={ELabels.MARKETPLACE_SUCCESS_FEE_LABEL}
              input={
                <SuccessContainer>
                  <div className="min-input">
                    <NumberFormat
                      id="minSuccessFee"
                      name="minSuccessFee"
                      allowNegative={false}
                      thousandSeparator
                      value={Number.parseFloat(values.minSuccessFee)}
                      onValueChange={(e: any) => {
                        setFieldTouched('minSuccessFee');
                        setFieldValue('minSuccessFee', e.value);
                      }}
                      onBlur={handleBlur}
                      customInput={MinFeeInput}
                      decimalScale={2}
                      isAllowed={maxPercentValueAllowed}
                    />
                  </div>
                  <div className="max-input">
                    <NumberFormat
                      id="maxSuccessFee"
                      name="maxSuccessFee"
                      allowNegative={false}
                      thousandSeparator
                      value={Number.parseFloat(values.maxSuccessFee)}
                      onValueChange={(e: any) => {
                        setFieldTouched('maxSuccessFee');
                        setFieldValue('maxSuccessFee', e.value);
                      }}
                      onBlur={handleBlur}
                      customInput={MaxFeeInput}
                      decimalScale={2}
                      isAllowed={maxPercentValueAllowed}
                    />
                  </div>
                </SuccessContainer>
              }
            />
          </SideDrawerContent>
        </SideDrawer>
      )}
    </Formik>
  );
}

export default CreateMMDealSideDrawer;
