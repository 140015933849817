import React, { ReactNode, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { ContextMenu, CaretIcon, EDirections, LoadingRectangle } from '@dealsyte/poki';
import EventService from 'utils/socket/EventService';
import { UserContext } from 'app/users/context/UserContext';
import UsersSettingsModal from 'app/users/components/UsersSettingsModal/UsersSettingsModal';
import { ERoutePatterns } from 'app/core-tools/due-diligence/types/types';
import { selectUnreadNotifications } from 'utils/socket/selectors';
import { useHistory } from 'react-router-dom';

import { IState as IDiligenceState } from '../../../../core-tools/due-diligence/types/diligence';
import { IProps, IStateProps, ELabels } from './TypedHeaderSessionTools';
import {
  ContainerOption,
  EmailOption,
  MainContainer,
  TriggerContainer,
} from './StyledHeaderSessionTools';
import ModalClosePrompt from 'app/common/components/Modal/ModalClosePrompt';
import { HStack } from 'app/common/components';
import { ExitIcon, LogOut2Icon, UserIcon } from 'app/common/icons';
/**
 * Where all user tools are rendered, such as logout button, settings button and go to home link
 * @param notifications Array of active and unviewed user notificacions
 */
export function HeaderSessionTools(props: IProps) {
  const [showConfirmLogoutModal, setShowConfirmLogoutModal] = useState(false);
  const { notifications } = props;
  const history = useHistory();

  const {
    id: userId,
    displayName: userName,
    logout,
    email,
    createdAt,
    lastLoginInDealAt,
    side,
    role,
    isUserLoading,
    loadingProfileData,
  } = useContext(UserContext);

  let notificationCount: number | ReactNode =
    (Array.isArray(notifications) && notifications.length) || 0;

  if (notificationCount && notificationCount > 9) {
    notificationCount = <span style={{ marginRight: -1 }}>9+</span>;
  }

  if (
    process.env.REACT_APP_INTERCOM_APP_ID &&
    window.Intercom &&
    userId !== '' &&
    userName !== 'Loading'
  ) {
    window.Intercom('update', {
      email,
      user_id: userId,
      name: userName,
      created_at: createdAt,
      hide_default_launcher: false,
      lastLoginInDealAt,
      dealSide: side,
      dealRole: role,
    });
  }

  return (
    <>
      <MainContainer>
        {loadingProfileData || isUserLoading ? (
          <LoadingRectangle
            rectangleStyle={{
              borderRadius: '6px',
              backgroundImage: 'linear-gradient(to right, #e2e1e1 8%, #e0dddd 18%, #e8e8e8 33%)',
            }}
            height={24}
            width={114}
          />
        ) : (
          <ContextMenu
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              minWidth: '210px',
            }}
            actions={[
              {
                label: (
                  <>
                    <EmailOption>
                      <p>{email}</p>
                    </EmailOption>
                  </>
                ),
                style: {
                  background: '#FEFEFE',
                },
              },
              {
                label: (
                  <>
                    <ContainerOption>
                      <UserIcon width="16px" height="16px" color="#47455F" />
                      <p>{ELabels.USER_SETTINGS}</p>
                    </ContainerOption>
                  </>
                ),
                onClick: () => history.push(ERoutePatterns.MY_ACCOUNT),
              },
              {
                label: (
                  <>
                    {' '}
                    <ContainerOption>
                      <LogOut2Icon width="16" height="16" color="#47455F" />
                      <p>{ELabels.LOG_OUT}</p>
                    </ContainerOption>
                  </>
                ),
                onClick: () => setShowConfirmLogoutModal(true),
              },
            ]}
            triggerButton={
              <TriggerContainer>
                <span>{userName}</span>{' '}
                <CaretIcon
                  direction={EDirections.down}
                  style={{ width: '7px', height: '4px', marginLeft: '8px' }}
                  color="#47455F"
                />
              </TriggerContainer>
            }
          />
        )}
      </MainContainer>
      <UsersSettingsModal
        onHide={() => {
          EventService.triggerEvent('MODAL_HIDE', ['account_settings']);
        }}
      />
      <ModalClosePrompt
        onAbort={() => setShowConfirmLogoutModal(false)}
        onConfirm={() => history.push(ERoutePatterns.LOGOUT)}
        visible={showConfirmLogoutModal}
        uiConfig={() => ({
          title: (
            <HStack spacing={16} alignItems="center">
              <ExitIcon width={21} height={26} color="#6A678E" />
              <span>Confirm logout</span>
            </HStack>
          ),
          description: 'Are you sure you want to log out from My Finalis?',
          confirmLabel: 'Log me out',
          declineLabel: 'Cancel',
        })}
      />
    </>
  );
}
HeaderSessionTools.displayName = 'HeaderSessionTools';

const mapStateToProps = (state: IDiligenceState) => ({
  notifications: selectUnreadNotifications(state) || [],
});

const ConnectedTopbarSessionTools = connect<IStateProps, {}, {}, IDiligenceState>(mapStateToProps)(
  HeaderSessionTools
);

export default ConnectedTopbarSessionTools;
